///////////////////////////////////////////////////////////////////////////////
//  # Variables
//--------------------------------
//    Define all your global variables in this file
///////////////////////////////////////////////////////////////////////////////

// ## Colours
//---------------------------------
//    Colors variables are used with the syntax below
//    const EmotionComponent = styled.selector`
//      $rule:  ${colors.colorName};
//    `
//    not color names as it won't affect your project in the long run
///////////////////////////////////////////////////////////////////////////////

const colors = {
  primaryLightest: '#dfdfdf',
  primaryLight: '#bfbfbf',
  primary: '#aaaaaa',
  primaryDark: '#6b6b6b',
  primaryDarkest: '#2B2B2B',
  primaryDarkestAlt: '#383838'
}

// # Media Queries breakpoints
//    MQ variables are defined as number and are used with the syntax below.
//
//      const EmotionComponent = styled.selector`
//        @media (min-width: ${breakpoints.small}px) {
//          ...
//        }
//      `
//
//    Try to use abstract names and not something specific as mobile, as it
//    won't affect your project in the long run
///////////////////////////////////////////////////////////////////////////////
const breakpoints = {
  phone: 600,
  tablet: 960,
  desktop: 1280,
  largeDesktop: 1440
}

const typography = {
  h1: '200 3rem "Montserrat", sans-serif',
  h1Minor: '200 2rem "Montserrat", sans-serif',
  h2: '200 1.5rem "Montserrat", sans-serif',
  h3: '500 1.5rem "Montserrat", sans-serif',
  paragraph: '400 1rem "Montserrat", sans-serif',
  paragraphMinor: '400 .875rem "Montserrat", sans-serif',

  h1Desk: '200 8.666666666666667rem "Montserrat", sans-serif',
  h1DeskMinor: '200 5.333333333333333‬rem "Montserrat", sans-serif',
  h2Desk: '200 4rem "Montserrat", sans-serif',
  h2DeskMinor: '200 3.555555555555556rem "Montserrat", sans-serif',
  h3Desk: '500 1.5rem "Montserrat", sans-serif',
  paragraphDesk: '400 1rem "Montserrat", sans-serif',
  paragraphDeskMinor: '400 .8888888888888889rem "Montserrat", sans-serif',

  base: '100%',
  baseDesk: '112.5%',
  display: '"Montserrat", sans-serif',
  body: '"Montserrat", sans-serif'
}

export default { colors, breakpoints, typography }
