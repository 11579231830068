import { StarlightConfig, StarlightResponse, WorkspaceId } from './types'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'

export default class StarlightClient {
  private debug: boolean
  private baseUrl: string
  private workspace: WorkspaceId

  constructor(config: StarlightConfig = {}) {
    this.configure(config)
  }

  public configure({ workspace, baseUrl, debug }: StarlightConfig) {
    this.baseUrl = baseUrl
    this.workspace = workspace
    this.debug = debug
  }

  private getBasePath() {
    if (!this.workspace) {
      throw new Error(
        'No Starlight workspace was configured. You may need to set one using Starlight.configure().'
      )
    }

    return `${this.baseUrl}/workspaces/${this.workspace}`
  }

  private error(message?: any, ...optionalParams: any[]) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.error(message, ...optionalParams)
    }
  }

  private log(message?: any, ...optionalParams: any[]) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log(message, ...optionalParams)
    }
  }

  private async get(path: string, options: AxiosRequestConfig = {}) {
    try {
      const response = await axios.get<StarlightResponse>(
        this.getBasePath() + path,
        options
      )

      this.log(`Starlight - GET: ${path} — ${response.status}`)

      return response.data
    } catch (_error) {
      const error: AxiosError = _error

      this.error(
        `Starlight - GET failed: ${path} — ${
          error.response?.status ?? 'UNKNOWN'
        } (${error.toString()})`
      )

      throw error
    }
  }

  singletons = {
    get: async (id: string) => {
      return this.get(`/singletons/${id}`)
    },
  }

  entries = {
    list: async (id: string, options: AxiosRequestConfig = {}) => {
      return this.get(`/models/${id}/entries`, options)
    },
    get: async (
      model: string,
      entry: string,
      options: AxiosRequestConfig = {}
    ) => {
      return this.get(`/models/${model}/entries/${entry}`, options)
    },
  }

  collections = {
    listItems: async (collection: string, options: AxiosRequestConfig = {}) => {
      return this.get(`/collections/${collection}/items`, options)
    },
  }

  categories = {
    list: async (model: string, options: AxiosRequestConfig = {}) => {
      return this.get(`/models/${model}/categories`, options)
    },
    get: async (
      model: string,
      category: string,
      options: AxiosRequestConfig = {}
    ) => {
      return this.get(`/models/${model}/categories/${category}`, options)
    },
    listEntries: async (
      model: string,
      category: string,
      options: AxiosRequestConfig = {}
    ) => {
      return this.get(
        `/models/${model}/categories/${category}/entries`,
        options
      )
    },
  }
}
