import App from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Global, withTheme, ThemeProvider } from '@emotion/react'

import Starlight from 'Starlight'

import cssBase from 'styles/globals'
import siteTheme from 'styles/theme'

import * as gtag from 'vendor/gtag'
import { GA_TRACKING_ID } from 'constants/tags'
import '../public/fonts.css'

const validGA = /UA-\d{4,10}-\d{1,4}/.test(GA_TRACKING_ID)

// Will be called once for every metric that has to be reported.
export function reportWebVitals({ label, name, value, id }) {
  if (validGA && process.env.NODE_ENV === 'production') {
    gtag.event({
      action: name,
      category: `Next.js - ${label} - Web Vitals`,
      label: id, // id unique to current page load
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    })
  }
}

Starlight.configure({
  workspace: process.env.NEXT_PUBLIC_STARLIGHT_WORKSPACE,
  baseUrl: process.env.NEXT_PUBLIC_STARLIGHT_BASE_URL,
  debug: process.env.STARLIGHT_DEBUG === 'true',
})

const GlobalStyle = withTheme(({ theme }) => <Global styles={cssBase(theme)} />)

const MyApp = ({ Component, pageProps, footer, header }) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (validGA && process.env.NODE_ENV === 'production') {
        gtag.pageview(url)
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  return (
    <ThemeProvider theme={siteTheme}>
      <GlobalStyle />
      <Component {...pageProps} footer={footer} header={header}/>
    </ThemeProvider>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const [ appProps, footer, header ] = await Promise.all([
    App.getInitialProps(appContext),
    Starlight.singletons.get('rodape'),
    Starlight.singletons.get('cabecalho'),
  ])
  return { ...appProps, footer, header }
}

export default MyApp
